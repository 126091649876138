

export function logPrint(message: any) {
    console.log(message);
}


export function logError(error: any, message?: String) {
    console.error(error);
    if (message) {
        console.error(message);
    }
    window.dtrum && window.dtrum.reportError(error);
}

export function logCustomError(key: string, value: string, hint: string) {
    window.dtrum && window.dtrum.reportCustomError(key, value, hint);
}


export function logInfo(message: any) {
    console.info(message);
}

export function logTrace(message: any) {
    console.info(message);
}
