"use client";

import {
  type GetWatchlistByNameResponse,
  queryOptionsGetWatchlistByName,
  queryOptionsGetWatchlistNames,
} from "#app/_api/portfolio-service/portfolio-service";
import { logError } from "#app/lib/logger";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  type CardHeaderProps,
  type CardRootProps,
} from "#ui/card";
import { GainLoss } from "#ui/gain-loss";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { cx } from "#ui/style.utils";
import { H2, P } from "#ui/typography";
import { QueryClient, useQuery } from "@tanstack/react-query";
import Image from "next/image";
import { ErrorCardContent } from "../cards/ErrorCard";
import { PendingCardContent } from "../cards/PendingCard";
import { formatCurrency } from "../textFormaters/Money";

const REFETCH_INTERVAL = 60 * 1000; // 1min

export function CardWatchlist(props: CardRootProps) {
  const resultsWatchlistNames = useQuery(
    {
      ...queryOptionsGetWatchlistNames(),
      refetchInterval: REFETCH_INTERVAL,
    },
    new QueryClient(),
  );

  // Derive data needed to enable the second query
  const firstWatchlistName = resultsWatchlistNames.data?.[0] ?? "";
  const isFoundFirstWatchlistName = firstWatchlistName.length > 0;

  const resultsWatchlistByName = useQuery({
    ...queryOptionsGetWatchlistByName({ name: firstWatchlistName }),
    enabled: isFoundFirstWatchlistName,
    refetchInterval: REFETCH_INTERVAL,
  });

  if (resultsWatchlistNames.isPending) {
    return <CardWatchlistPending {...props} />;
  }

  if (resultsWatchlistNames.isError) {
    logError(resultsWatchlistNames.error);
    return <CardWatchlistError {...props} />;
  }

  // Return early if we don't need data from the second query, also
  // `isPending` always equals `true` for subsequent disabled serial queries.
  if (!isFoundFirstWatchlistName) {
    return <CardWatchlistEmpty {...props} />;
  }

  if (resultsWatchlistByName.isPending) {
    return <CardWatchlistPending {...props} />;
  }

  if (resultsWatchlistByName.isError) {
    logError(resultsWatchlistByName.error);
    return <CardWatchlistError {...props} />;
  }

  const data = resultsWatchlistByName.data
    .toSorted((a, b) => {
      const aStr = a.symbol.toUpperCase();
      const bStr = b.symbol.toUpperCase();
      return aStr < bStr ? -1 : aStr > bStr ? 1 : 0;
    })
    .slice(0, 5);

  if (data.length === 0) {
    return <CardWatchlistEmpty {...props} />;
  }

  return <CardWatchlistSuccess {...props} data={data} />;
}

function CardWatchlistHeader(props: CardHeaderProps) {
  return (
    <CardHeader {...props} palette="gray">
      <H2 size="h6" className="flex items-center gap-10px">
        <Icon name="baird-favorite" size="baird-md" />
        <span>Watchlist</span>
      </H2>
    </CardHeader>
  );
}

function CardWatchlistPending(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardWatchlistHeader />
      <CardContent>
        <PendingCardContent />
      </CardContent>
    </Card>
  );
}

function CardWatchlistError(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardWatchlistHeader />
      <CardContent>
        <ErrorCardContent />
      </CardContent>
    </Card>
  );
}

function CardWatchlistEmpty(props: CardRootProps) {
  return (
    <Card {...props}>
      <CardWatchlistHeader />
      <CardContent>
        <WatchlistEmpty />
      </CardContent>
      <CardFooter palette="white">
        <LinkButton href="/investing-tools/watchlist">Add</LinkButton>
      </CardFooter>
    </Card>
  );
}

function CardWatchlistSuccess({
  data,
  ...props
}: CardRootProps & { data: Awaited<GetWatchlistByNameResponse> }) {
  return (
    <Card {...props}>
      <CardWatchlistHeader />
      <CardContent className="p-0">
        <WatchlistTable data={data} />
      </CardContent>
      <CardFooter palette="white">
        <LinkButton href="/investing-tools/watchlist">See all</LinkButton>
      </CardFooter>
    </Card>
  );
}

function WatchlistEmpty() {
  return (
    <div className="space-y-30px text-center">
      <Image
        alt=""
        src="/Images/image-empty-state.png"
        width="420"
        height="321"
        className="mx-auto w-148px sm:w-211px"
        priority
      />
      <P size="h6">You haven&rsquo;t added anything to your watchlist.</P>
    </div>
  );
}

function WatchlistTable({
  className,
  data,
  ...props
}: React.ComponentProps<"table"> & {
  data: Awaited<GetWatchlistByNameResponse>;
}) {
  return (
    <table {...props} className={cx("w-full text-sm leading-none", className)}>
      <thead>
        <tr className="*:bg-shade-2 *:px-30px *:py-13px">
          <th className="w-2/5 text-left">Symbol</th>
          <th className="text-right">Price</th>
          <th className="whitespace-nowrap text-right">
            Change{" "}
            <abbr title="Percentage" className="no-underline">
              %
            </abbr>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((el) => (
          <tr
            className="*:border-t *:border-t-shade-10 *:px-30px *:py-10px"
            key={el.symbol}
          >
            <th className="text-left font-normal" scope="row">
              <LinkButton
                href={`/investing-tools/stocks/${el.symbol}-US`}
                variant="link"
              >
                {el.symbol}
              </LinkButton>
            </th>
            <td className="text-right">
              {typeof el.price === "number" ? formatCurrency(el.price) : "N/A"}
            </td>
            <td className="text-right">
              <GainLoss
                value={el.percentChange}
                format="percent"
                className="-mr-3px flex-row-reverse"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
