import { getJson } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";

type AutoCompleteResult = {
  symbol: string;
  name: string;
  exchangeId: string;
  securityTypeId: string;
  identifier: string;
  country: string;
};

type AutoCompleteResults = {
  data: AutoCompleteResult[];
};

const GATEWAY_PATH = "marketresearch/v1";

export type GetAutocompleteParams = {
  search: string;
  numberOfResults?: number;
};

export type GetAutocompleteResponse = ReturnType<typeof getAutocomplete>;

export async function getAutocomplete({
  search,
  numberOfResults = 10,
}: GetAutocompleteParams): Promise<AutoCompleteResults> {
  const searchParams = new URLSearchParams({ search });
  if (numberOfResults && numberOfResults > 0) {
    searchParams.set("numberOfResults", `${numberOfResults}`);
  }
  const paramsStr = searchParams.size > 0 ? `?${searchParams.toString()}` : "";
  return await getJson<AutoCompleteResults>(
    `/${GATEWAY_PATH}/Autocomplete${paramsStr}`,
  );
}

export const queryKeysExternalMarketResearchService = createQueryKeys(
  "ExternalMarketResearchService",
  {
    autocomplete: (params: GetAutocompleteParams) => ({
      queryKey: [params],
      queryFn: () => getAutocomplete(params),
    }),
  },
);

export function useQueryOptionsGetAutocomplete(params: GetAutocompleteParams) {
  return queryKeysExternalMarketResearchService.autocomplete(params);
}
