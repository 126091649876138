"use client";

import { SelectAccounts } from "#app/(secured)/documents/_shared/SelectAccounts";
import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { useJwtAccountsSorted } from "#app/(unauthorized)/authentication/useJwt";
import {
  type GetHistoricalValuesResponse,
  queryOptionsGetHistoricalValues,
} from "#app/_api/portfolio-service/portfolio-service";
import { logError } from "#app/lib/logger";
import { Button } from "#ui/button";
import { type CardRootProps, Card, CardContent, CardHeader } from "#ui/card";
import { Icon } from "#ui/icon";
import { cx } from "#ui/style.utils";
import { H2, P } from "#ui/typography";
import { tz } from "@date-fns/tz";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import {
  format,
  getTime,
  parseISO,
  startOfMonth,
  startOfToday,
  startOfYear,
  subMonths,
} from "date-fns";
import Highcharts from "highcharts";
import HighchartsReact, {
  type HighchartsReactProps,
} from "highcharts-react-official";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsExporting from "highcharts/modules/exporting";
import Image from "next/image";
import { useState } from "react";
import { ErrorCardContent } from "../cards/ErrorCard";
import { PendingCardContent } from "../cards/PendingCard";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
  highchartsAccessibility(Highcharts);
}

// -----------------------------------------------------------------------------
// page
// -----------------------------------------------------------------------------

// Stabilize `toDate`
const now = new Date();

export function CardMarketValueHistory(props: CardRootProps) {
  // Account data
  const accountsSelectable = useJwtAccountsSorted();

  // Chart filter state
  const { accountsSelected, range, handleChangeAccounts, handleChangeRange } =
    useFilters(accountsSelectable);

  // Chart data filtered
  const { data, error, isError, isFetching, isPending, isSuccess } = useQuery({
    ...queryOptionsGetHistoricalValues({
      acctIndex: deriveAcctIndex(accountsSelectable, accountsSelected),
      annualizedOnly: undefined,
      fromDate: deriveFromDate(range, now)?.toISOString().substring(0, 10), // yyyy-MM-dd
      toDate: now.toISOString().substring(0, 10), // yyyy-MM-dd
    }),
    /**
     * Using `placeholderData` in combinations with
     * `MarketValueHistoryChartContainer` and `isFetching`
     * for better UX
     */
    placeholderData: keepPreviousData,
  });

  if (isError) {
    logError(error);
  }

  const isEmpty = isSuccess && data.length === 0;
  const isFound = isSuccess && data.length > 0;

  return (
    <Card {...props}>
      <CardHeader>
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-line-chart" size="baird-md" />
          <span>Market Value History</span>
        </H2>
      </CardHeader>
      <CardContent>
        <div className="grid gap-30px">
          <MarketValueHistoryFilters
            accountsSelectable={accountsSelectable}
            accountsSelected={accountsSelected}
            onChangeAccounts={handleChangeAccounts}
            onChangeRange={handleChangeRange}
            range={range}
          />
          {isPending ? <PendingCardContent className="md:min-h-400px" /> : null}
          {isError ? <ErrorCardContent className="md:min-h-400px" /> : null}
          {isEmpty ? <CardMarketValueHistoryEmpty /> : null}
          {isFound ? (
            <CardMarketValueHistoryFound data={data} isFetching={isFetching} />
          ) : null}
        </div>
      </CardContent>
    </Card>
  );
}

function CardMarketValueHistoryEmpty() {
  return (
    <div className="space-y-30px text-center">
      <Image
        alt=""
        src="/Images/image-empty-state.png"
        width="420"
        height="321"
        className="mx-auto w-148px sm:w-211px"
        priority
      />
      <P size="h6">You have no Market Value History.</P>
    </div>
  );
}

function CardMarketValueHistoryFound({
  data,
  isFetching,
  ...props
}: React.ComponentProps<"div"> & {
  data: Awaited<GetHistoricalValuesResponse>;
  isFetching?: boolean;
}) {
  const chartData = formatData(data);
  return (
    <MarketValueHistoryChartContainer {...props} isFetching={isFetching}>
      <MarketValueHistoryChart data={chartData} />
    </MarketValueHistoryChartContainer>
  );
}

// -----------------------------------------------------------------------------
// filters
// -----------------------------------------------------------------------------

type AccountId = string;

function deriveAcctIndex(
  accountsSelectable: AccountId[],
  accountsSelected: AccountId[],
) {
  const acctIndexes = getCurrentJwt().getAccountIndex(accountsSelected);

  const isFoundAndDifferent =
    acctIndexes.length > 0 && acctIndexes.length !== accountsSelectable.length;

  if (isFoundAndDifferent) {
    return acctIndexes;
  }

  return undefined;
}

type RangeValue = "1D" | "1M" | "3M" | "6M" | "1Y" | "YTD" | "ALL";

type RangeOption = {
  title: string;
  label: string;
  value: RangeValue;
};

const rangeOptions = [
  { title: "Today", label: "1D", value: "1D" },
  { title: "Last month", label: "1M", value: "1M" },
  { title: "Last 3 months", label: "3M", value: "3M" },
  { title: "Last 6 months", label: "6M", value: "6M" },
  { title: "Last year", label: "1Y", value: "1Y" },
  { title: "Year to date", label: "YTD", value: "YTD" },
  { title: "Inception to date", label: "ALL", value: "ALL" },
] as const satisfies RangeOption[];

function deriveFromDate(range: RangeValue, now: Date) {
  switch (range) {
    case "1D":
      return startOfToday();
    case "1M":
      return startOfMonth(subMonths(now, 1));
    case "3M":
      return startOfMonth(subMonths(now, 3));
    case "6M":
      return startOfMonth(subMonths(now, 6));
    case "1Y":
      return startOfMonth(subMonths(now, 12));
    case "YTD":
      return startOfYear(now);
    case "ALL":
    default:
      return undefined;
  }
}

function useFilters(accountsSelectable: AccountId[]) {
  // State
  const [accountsSelected, setAccountsSelected] =
    useState<AccountId[]>(accountsSelectable);
  const [range, setRange] = useState<RangeValue>("6M");

  // Handlers
  const handleChangeAccounts = (val: AccountId[]) => setAccountsSelected(val);
  const handleChangeRange = (val: RangeValue) => setRange(val);

  return {
    accountsSelected,
    range,
    handleChangeAccounts,
    handleChangeRange,
  };
}

function MarketValueHistoryFilters({
  accountsSelectable,
  accountsSelected,
  className,
  onChangeAccounts,
  onChangeRange,
  range,
  ...props
}: React.ComponentProps<"div"> & {
  accountsSelectable: AccountId[];
  accountsSelected: AccountId[];
  onChangeAccounts: (accounts: AccountId[]) => void;
  onChangeRange: (range: RangeValue) => void;
  range: RangeValue;
}) {
  return (
    <div
      {...props}
      className={cx(
        "flex flex-col gap-20px md:flex-row md:items-center md:justify-between",
        className,
      )}
    >
      <div>
        <SelectAccounts
          id="accounts"
          name="accounts"
          accountsSelectable={accountsSelectable}
          accountsSelected={accountsSelected}
          onValueChange={onChangeAccounts}
          disabled={accountsSelectable.length < 2}
          className="md:w-240px md:min-w-240px"
        />
      </div>
      <div
        role="group"
        aria-label="Date range"
        className="grid grid-cols-7 gap-5px md:flex"
      >
        {rangeOptions.map((el) => (
          <Button
            value={el.value}
            onClick={() => onChangeRange(el.value)}
            role="radio"
            aria-checked={el.value === range}
            size="sm"
            variant="outline"
            palette="neutral"
            className="aria-checked:bg-primary-100 aria-checked:text-white"
            key={el.value}
          >
            <abbr title={el.title} className="no-underline">
              {el.label}
            </abbr>
          </Button>
        ))}
      </div>
    </div>
  );
}

// -----------------------------------------------------------------------------
// chart
// -----------------------------------------------------------------------------

// Stablize constructors for performance
const IntlNumberCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

type MarketValueHistoryChartItem = {
  custom: {
    date: string;
    value: string;
  };
  x: number;
  y: number;
};

/** Chart a11y, data, and display formatting */
function formatData(values: Awaited<GetHistoricalValuesResponse>) {
  const seriesData: MarketValueHistoryChartItem[] = values.map((el) => {
    // Adjust for universal time returned by the service
    const date = parseISO(el.asOfDate, { in: tz("Etc/GMT-6") });
    return {
      custom: {
        // ex: January 1st, 2025
        date: format(date, "LLLL do, yyyy"),
        // ex: $9,999,999
        value: IntlNumberCurrency.format(el.value),
      },
      // ms timestamp, ex: 1735711200000
      x: getTime(date),
      y: el.value,
    };
  });
  const seriesDataSortedByValue = seriesData.toSorted((a, b) => a.y - b.y);

  return {
    seriesData,
    xAxisRangeDescription: [
      "Date Range:",
      seriesData.at(0)!.custom.date,
      "to",
      seriesData.at(-1)!.custom.date,
    ].join(" "),
    yAxisRangeDescription: [
      "Market Value Range:",
      seriesDataSortedByValue.at(0)!.custom.value,
      "to",
      seriesDataSortedByValue.at(-1)!.custom.value,
    ].join(" "),
  };
}

/** Wrapping "areaspline" charts with better async feedback */
function MarketValueHistoryChartContainer({
  children,
  className,
  isFetching,
  ...props
}: React.ComponentProps<"div"> & {
  isFetching?: boolean;
}) {
  // Chart resizing based on grandparent?! magic `overflow-x-auto` works?!
  return (
    <div {...props} className={cx("relative overflow-x-auto", className)}>
      <div>{children}</div>
      {isFetching ? (
        <PendingCardContent className="absolute inset-0 bg-white/50 md:pt-0" />
      ) : null}
    </div>
  );
}

function MarketValueHistoryChart({
  className,
  containerProps,
  data,
  ...props
}: Omit<HighchartsReactProps, "highcharts" | "options"> & {
  data: ReturnType<typeof formatData>;
}) {
  return (
    <HighchartsReact
      {...props}
      containerProps={{ ...containerProps, className }}
      highcharts={Highcharts}
      options={
        {
          credits: { enabled: false },
          exporting: { enabled: false },
          legend: { enabled: false },
          title: { text: "" }, // handled by card header
          chart: {
            type: "areaspline",
            zooming: { type: "x" },
          },
          tooltip: {
            useHTML: true,
            shared: true,
            backgroundColor: "transparent",
            borderColor: "transparent",
            shadow: false,
            padding: 0,
            formatter() {
              // @ts-expect-error - highchart types are terrible
              const el = this.point as MarketValueHistoryChartItem;
              return `<div style="
              padding: 11px 15px;
              border-radius: 6px;
              font: 400 14px/24px var(--font-myriad-pro);
              border: 1px solid #E6E9EC;
              background-color: white;
              color: #1A1919;
              box-shadow:
                rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                rgba(0, 0, 0, 0) 0px 0px 0px 0px,
                rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
                rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
            ">
              <div style="font-weight:600">${el.custom.date}</div>
              <div>${el.custom.value}</div>
            </div>`;
            },
          },
          yAxis: {
            title: { text: null }, // handled by `yAxisRangeDescription`
            accessibility: { rangeDescription: data.yAxisRangeDescription },
          },
          xAxis: {
            type: "datetime",
            accessibility: { rangeDescription: data.xAxisRangeDescription },
          },
          plotOptions: {
            areaspline: {
              marker: {
                fillColor: "#00356D00", // transparent
                lineColor: "#FFFFFF00", // transparent
                radius: 2,
                states: {
                  hover: {
                    fillColor: "#00356D",
                    lineColor: "#FFFFFF",
                  },
                },
              },
              color: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, "#00346B1A"],
                  [1, "#00346B05"],
                ],
              },
              lineColor: "#00356D",
              lineWidth: 2,
              states: {
                hover: {
                  lineWidth: 2,
                },
              },
              threshold: null,
            },
          },
          series: [
            {
              type: "areaspline",
              name: "All Accounts",
              data: data.seriesData,
            },
          ],
        } satisfies Highcharts.Options
      }
    />
  );
}
