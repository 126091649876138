import { getJson } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { queryOptions } from "@tanstack/react-query";
import { getAccountGroupValues } from "./get-account-groups";

type AssetAllocationCategorizationType = "AssetClass" | "AssetCategory";

type AssetCategorizationSecurity = {
  name: string;
  symbol: string;
  cusip: string;
  secNbr: string;
  value: number;
  percent: number;
  percentChange: number;
  change: number;
  allocationChange: number;
  allocationPercent: number;
  gainLoss: number;
  costBasis: number;
};

type AssetCategorizationAccount = {
  accountNumber: string;
  accountNickname: string;
  accountType: string;
  value: number;
  percent: number;
};

type AssetCategorization = {
  name: string;
  value: number;
  percent: number;
  change: number;
  percentChange: number;
  instruments: AssetCategorizationSecurity[];
  accounts: AssetCategorizationAccount[];
};

type AssetAllocation = {
  categorizationType: AssetAllocationCategorizationType;
  categorizations: AssetCategorization[];
};

type HistoricalValue = {
  value: number;
  change: number;
  percentChange: number;
  asOfDate: string;
};

export type Portfolio = {
  accounts: Account[];
  totals: Totals;
};

export type Totals = {
  value: number;
  change: number;
  percentChange: number;
  balances: Balances;
  asOfDate: string;
};

export type Balances = {
  cashValue: number;
};

export type Account = {
  number: string;
  value: number;
  nickname: string;
  accountType: string;
  percentChange: number;
};

export interface AccountActivity {
  transactions: AccountTransaction[];
  page: number;
  pageCount: number;
  pageSize: number;
}

interface AccountTransaction {
  accountNumber: string;
  accountNickname: string;
  accountType: string;
  tradeDate: string;
  activityName: string;
  activityDescriptionType: string;
  activityType: string;
  desc: string;
  quantity: number;
  price: number;
  amount: number;
  source: string;
  instrument: Instrument | null;
  fundTransferMetadata: FundTransferMetadata | null;
}

interface Instrument {
  name: string;
  securityType: string;
  investmentType: string | null;
  assetClasses: InstrumentAssetAllocation[];
  assetCategories: InstrumentAssetAllocation[];
  symbol: string;
  cusip: string;
  secNbr: string | null;
  canQuote: boolean;
  quote: Quote | null;
  optionInfo: OptionInfo;
}

interface InstrumentAssetAllocation {
  name: string;
  percent: number;
}

interface Quote {
  price: number;
  change: number;
  percentChange: number;
}

interface OptionInfo {
  strikePrice: number | null;
  maturityDate: string | null;
  type: string | null;
}

interface FundTransferMetadata {
  id: string;
  instructionNumber: string;
  submittedDate: string;
  status: "Pending" | "Completed" | "Error";
  remote: RemoteAccount;
  type: "CheckDeposit" | "ACH";
}

interface RemoteAccount {
  accountType: "Checking" | "Loan" | "Savings";
  institutionName: string;
  accountNumberLastFour: string;
  isAliasAccount: boolean;
  statementAccountNumber: string;
}

type WatchlistNames = string[];

type WatchlistQuote = {
  symbol: string;
  price: number | null;
  description: string;
  percentChange: number | null;
  change: number | null;
};

const GATEWAY_PATH = "pwmportfolios/v1";

export type GetAssetAllocationsParams = {
  categorizationType: AssetAllocationCategorizationType;
  acctIndex?: number[];
};

export type GetAssetAllocationsResponse = ReturnType<
  typeof getAssetAllocations
>;

export async function getAssetAllocations({
  categorizationType,
  acctIndex,
}: GetAssetAllocationsParams): Promise<AssetAllocation> {
  const params = new URLSearchParams({ categorizationType });
  if (acctIndex && acctIndex.length > 0) {
    params.set("acctIndex", acctIndex.join(","));
  }
  return await getJson<AssetAllocation>(
    `/${GATEWAY_PATH}/AssetAllocations?${params.toString()}`,
  );
}

export type GetHistoricalValuesParams = {
  fromDate?: string;
  toDate?: string;
  annualizedOnly?: boolean;
  acctIndex?: number[];
};

export type GetHistoricalValuesResponse = ReturnType<
  typeof getHistoricalValues
>;

export async function getHistoricalValues({
  fromDate,
  toDate,
  annualizedOnly,
  acctIndex,
}: GetHistoricalValuesParams = {}): Promise<HistoricalValue[]> {
  const params = new URLSearchParams();
  if (fromDate) params.set("fromDate", fromDate);
  if (toDate) params.set("toDate", toDate);
  if (annualizedOnly !== undefined) {
    params.set("annualizedOnly", String(annualizedOnly));
  }
  if (acctIndex && acctIndex.length > 0) {
    params.set("acctIndex", acctIndex.join(","));
  }
  return await getJson<HistoricalValue[]>(
    `/${GATEWAY_PATH}/HistoricalValues?${params.toString()}`,
  );
}

export type GetPortfolioParams = {
  includeDetails?: false;
  acctIndex?: number[];
  groupId?: string;
  pdsVersion?: number;
};

export async function getPortfolio({
  groupId,
}: GetPortfolioParams = {}): Promise<Portfolio> {
  const params = new URLSearchParams();
  if (groupId) params.set("groupId", groupId);

  const result = await getJson<Portfolio>(
    `/${GATEWAY_PATH}/Portfolio?${params.toString()}`,
  );
  return result;
}

export async function getPortfolioRecentActivity({
  startDate,
  acctIndex,
  categoryFilter,
  activitySortValue,
  sortDirection,
}: {
  /** Format: yyyy-MM-dd */
  startDate?: `${number}-${number}-${number}`;
  acctIndex?: number[];
  categoryFilter?:
    | "MoneyMarket"
    | "Money"
    | "Security"
    | "Trade"
    | "IncomeandExpense"
    | "VIPChecking"
    | "VIPCardActivity";
  activitySortValue?:
    | "1"
    | "Date"
    | "Activity"
    | "AssetClassification"
    | "Quantity"
    | "Description"
    | "Type"
    | "Symbol"
    | "Price"
    | "Amount";
  sortDirection?: "0" | "Descending" | "Ascending";
} = {}) {
  const searchParamsFiltered = [
    startDate ? `startDate=${startDate}` : null,
    acctIndex?.length && acctIndex.length > 0
      ? `acctIndex=${acctIndex.join(",")}`
      : null,
    categoryFilter ? `categoryFilter=${categoryFilter}` : null,
    activitySortValue ? `activitySortValue=${activitySortValue}` : null,
    sortDirection ? `sortDirection=${sortDirection}` : null,
  ].filter(Boolean);

  const searchParams =
    searchParamsFiltered.length > 0
      ? "?".concat(searchParamsFiltered.join("&"))
      : "";

  return await getJson<AccountActivity>(
    `/${GATEWAY_PATH}/RecentActivity${searchParams}`,
  );
}

export type GetWatchlistNamesResponse = ReturnType<typeof getWatchlistNames>;

export async function getWatchlistNames(): Promise<WatchlistNames> {
  return await getJson<WatchlistNames>(`/${GATEWAY_PATH}/Watchlist`, {
    pdsVersion: "2",
  });
}

export type GetWatchlistByNameParams = { name: string };
export type GetWatchlistByNameResponse = ReturnType<typeof getWatchlistByName>;

export async function getWatchlistByName({
  name,
}: GetWatchlistByNameParams): Promise<WatchlistQuote[]> {
  return await getJson<WatchlistQuote[]>(`/${GATEWAY_PATH}/Watchlist/${name}`, {
    pdsVersion: "2",
  });
}

export const portfolioQueryKeys = createQueryKeys("Portfolio", {
  accountGroupValues: {
    queryKey: null,
    queryFn: () => getAccountGroupValues(),
  },
  assetAllocations: (params: GetAssetAllocationsParams) => ({
    queryKey: [params],
    queryFn: () => getAssetAllocations(params),
  }),
  historicalValues: (params?: GetHistoricalValuesParams) => ({
    queryKey: [params],
    queryFn: () => getHistoricalValues(params),
  }),
  portfolio: (params?: GetPortfolioParams) => ({
    queryKey: [params],
    queryFn: () => getPortfolio(params),
  }),
  recentActivity: (
    params?: Parameters<typeof getPortfolioRecentActivity>[0],
  ) => ({
    queryKey: [params],
    queryFn: () => getPortfolioRecentActivity(params),
  }),
  watchlistNames: {
    queryKey: null,
    queryFn: getWatchlistNames,
  },
  watchlistByName: (params: GetWatchlistByNameParams) => ({
    queryKey: [params],
    queryFn: () => getWatchlistByName(params),
  }),
});

export function queryOptionsGetAssetAllocations(
  params: GetAssetAllocationsParams,
) {
  return portfolioQueryKeys.assetAllocations(params);
}

export function queryOptionsGetHistoricalValues(
  params?: GetHistoricalValuesParams,
) {
  return portfolioQueryKeys.historicalValues(params);
}

export function useQueryOptionsPortfolio(params?: GetPortfolioParams) {
  return queryOptions(portfolioQueryKeys.portfolio(params));
}

export function usePortfolioQueryOptions(params?: GetPortfolioParams) {
  const defaultData: Portfolio | undefined = undefined;
  return queryOptions({
    queryKey: portfolioQueryKeys.portfolio(params).queryKey,
    queryFn: (context) =>
      (
        portfolioQueryKeys
          .portfolio(params)
          .queryFn(context) as Promise<Portfolio>
      ).catch((_) => defaultData),
    staleTime: 0,
  });
}

export function queryOptionsGetWatchlistNames() {
  return portfolioQueryKeys.watchlistNames;
}

export function queryOptionsGetWatchlistByName(
  params: GetWatchlistByNameParams,
) {
  return portfolioQueryKeys.watchlistByName(params);
}


