import {
  Card,
  CardContent,
  type CardContentProps,
  type CardRootProps,
} from "#ui/card";
import { Icon } from "#ui/icon";
import { cx } from "#ui/style.utils";
import { H2 } from "#ui/typography";

interface ErrorCardContentProps extends React.ComponentPropsWithoutRef<"div"> {}

export function ErrorCardContent({
  children,
  className,
  ...props
}: ErrorCardContentProps) {
  return (
    <div
      {...props}
      className={cx(
        // Same classNames as PendingCardContent - Keep in sync!
        "grid place-content-center place-items-center gap-20px text-center md:min-h-280px md:py-30px",
        className,
      )}
    >
      <Icon
        name="baird-maintenance-circle"
        size="baird-lg"
        palette="warning"
        className="mb-5px"
      />
      <H2 size="h5" className="leading-none">
        Oops! It looks like we ran into an error.
      </H2>
      {children}
    </div>
  );
}

interface ErrorCardProps extends CardRootProps {
  children?: CardContentProps["children"];
}

export function ErrorCard({ children, ...props }: ErrorCardProps) {
  return (
    <Card variant="white-border-and-blur" {...props}>
      <CardContent>
        <ErrorCardContent>{children}</ErrorCardContent>
      </CardContent>
    </Card>
  );
}
