"use client";

import {
  type GetAssetAllocationsResponse,
  queryOptionsGetAssetAllocations,
} from "#app/_api/portfolio-service/portfolio-service";
import { ErrorCardContent } from "#app/_ui/components/cards/ErrorCard";
import { PendingCardContent } from "#app/_ui/components/cards/PendingCard";
import { logError } from "#app/lib/logger";
import { Card, CardContent, CardHeader, type CardRootProps } from "#ui/card";
import { Dot } from "#ui/dot";
import { type DotVariants } from "#ui/dot.styles";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { cx } from "#ui/style.utils";
import { H2, P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts";
import HighchartsReact, {
  type HighchartsReactProps,
} from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import Image from "next/image";
import { useEffect, useState } from "react";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}

/**
 * Only show for Clients, 3rd Parties, PAM
 * Visibility handled by different dashboard routes.
 */
export function CardAssetAllocation(props: CardRootProps) {
  const { data, error, isError, isPending, isSuccess } = useQuery(
    queryOptionsGetAssetAllocations({ categorizationType: "AssetCategory" }),
  );

  if (isError) {
    logError(error);
  }

  const isEmpty = isSuccess && data.categorizations.length === 0;
  const isFound = isSuccess && data.categorizations.length > 0;

  return (
    <Card {...props}>
      <CardHeader className="flex items-center justify-between">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-donut-chart" size="baird-md" />
          <span>Asset Allocation</span>
        </H2>
        <LinkButton href="/investments/holdings" variant="link">
          <span>See details</span>
          <Icon name="ms-navigate-next" />
        </LinkButton>
      </CardHeader>
      <CardContent
        className={isEmpty ? "pb-50px pt-[42px] sm:pt-32px" : undefined}
      >
        {isPending ? <PendingCardContent className="md:min-h-fit" /> : null}
        {isError ? <ErrorCardContent className="md:min-h-fit" /> : null}
        {isEmpty ? <CardAssetAllocationEmpty /> : null}
        {isFound ? <CardAssetAllocationFound data={data} /> : null}
      </CardContent>
    </Card>
  );
}

// Stablize constructors for performance
const IntlNumberCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});
const IntlNumberPercent = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

type DotPalette = NonNullable<DotVariants["palette"]>;

// Chart and table color values keyed by categorization
const categorizationMap = {
  "Cash and Cash Equivalents": { palette: "chart-green", color: "#669933" },
  Equities: { palette: "chart-teal", color: "#4FC0D2" },
  "Fixed Income": { palette: "chart-orange", color: "#E46D43" },
  Alternatives: { palette: "chart-purple", color: "#912187" },
  "Multi-Asset Class": { palette: "chart-gray", color: "#DDDDDD" },
  Other: { palette: "chart-yellow", color: "#EAA904" },
  Unclassified: { palette: "chart-lime", color: "#C6C600" },
} as const satisfies Record<string, { palette: DotPalette; color: string }>;

const defaultCategorization = categorizationMap["Other"];

// Chart and table formatting
type AssetAllocationChartItem = {
  color: string;
  custom: {
    value: string;
    percent: string;
    rawValue: number;
    rawPercent: number;
    fontSize: number;
  };
  name: string;
  y: number;
  events?: { click: () => void };
  linecap: "square" | "round";
};
type AssetAllocationTableItem = {
  name: string;
  palette: DotPalette;
  percent: string;
  value: string;
};

function formatData(data: Awaited<GetAssetAllocationsResponse>) {
  const chartData: AssetAllocationChartItem[] = [];
  const tableData: AssetAllocationTableItem[] = [];
  for (const el of data.categorizations) {
    const name = el.name;
    const y = el.percent;
    const rawValue = el.value;
    const rawPercent = el.percent;
    const { value, percent } = formatTotals({ rawValue, rawPercent });
    const fontSize = calcFontSize(el.value);
    const mapVal = categorizationMap[el.name as keyof typeof categorizationMap];
    const color = mapVal?.color ?? defaultCategorization.color;
    const palette = mapVal?.palette ?? defaultCategorization.palette;
    if (el.value >= 0) {
      chartData.push({
        name,
        linecap: "square",
        y,
        color,
        custom: {
          value,
          percent,
          rawValue,
          rawPercent,
          fontSize,
        },
      });
    }
    tableData.push({ name, value, percent, palette });
  }
  return { chartData, tableData };
}

function CardAssetAllocationEmpty() {
  return (
    <div className="space-y-30px text-center">
      <Image
        alt=""
        src="/Images/image-empty-state.png"
        width="420"
        height="321"
        className="mx-auto w-148px sm:w-211px"
        priority
      />
      <P size="h6">You have no asset allocations.</P>
    </div>
  );
}

function CardAssetAllocationFound({
  data,
}: React.ComponentProps<"div"> & {
  data: Awaited<GetAssetAllocationsResponse>;
}) {
  const { chartData, tableData } = formatData(data);
  return (
    <div className="grid place-items-center gap-30px md:grid-cols-2">
      <AssetAllocationChart
        data={chartData}
        /**
         * built-in chart padding = ~45px
         * adjusted size =  45px + desired size
         * margin offset = 45px * -0.5
         */
        className="-m-22px aspect-square size-295px max-w-full md:size-315px"
      />
      <AssetAllocationTable data={tableData} className="w-full md:w-full" />
    </div>
  );
}

function AssetAllocationChart({
  className,
  containerProps,
  data,
  ...props
}: Omit<HighchartsReactProps, "highcharts" | "options"> & {
  data: AssetAllocationChartItem[];
}) {
  const options = useAssetAllocationOptions(data);

  return (
    <HighchartsReact
      {...props}
      containerProps={{ ...containerProps, className }}
      highcharts={Highcharts}
      options={options}
    />
  );
}

function AssetAllocationTable({
  className,
  data,
  ...props
}: React.ComponentProps<"table"> & {
  data: AssetAllocationTableItem[];
}) {
  return (
    <table {...props} className={cx("text-sm leading-none", className)}>
      <thead className="sr-only">
        <tr>
          <th>Asset Category</th>
          <th>Market Value</th>
          <th>Percent of Market Value</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, idx) => (
          <tr
            className={cx(
              "*:py-16px",
              idx === 0 ? "" : "*:border-t *:border-t-shade-10",
            )}
            key={el.name}
          >
            <th className="w-1/2 text-left font-normal" scope="row">
              <div className="flex items-center gap-8px">
                <Dot
                  size="lg"
                  palette={el.palette}
                  aria-hidden="false"
                  aria-label={el.palette.replace("chart-", "")}
                />
                <span className="leading-sm">{el.name}</span>
              </div>
            </th>
            <td className="px-22px text-right">
              <div>{el.value}</div>
            </td>
            <td className="text-right font-semibold">
              <div>{el.percent}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function calcTotals(data: AssetAllocationChartItem[]) {
  return {
    rawValue: data.reduce(
      (curr: number, val: { custom: { rawValue: number } }) =>
        curr + val.custom.rawValue,
      0,
    ),
    rawPercent: data.reduce(
      (curr: number, val: { custom: { rawPercent: number } }) =>
        curr + val.custom.rawPercent,
      0,
    ),
  };
}

function formatTotals({
  rawValue,
  rawPercent,
}: {
  rawValue: number;
  rawPercent: number;
}) {
  return {
    value: IntlNumberCurrency.format(rawValue),
    percent: IntlNumberPercent.format(rawPercent / 100),
  };
}

function calcFontSize(rawValue: number) {
  return rawValue > 9_999_999 ? 28 : rawValue > 999_999 ? 32 : 40;
}

function useAssetAllocationOptions(data: AssetAllocationChartItem[]) {
  const [selected, setSelected] = useState<number | null>(null);

  let selectedItem = {
    name: "N/A",
    custom: {
      value: "N/A",
      percent: "N/A",
      fontSize: 40,
    },
  };
  if (selected === null) {
    const { rawValue, rawPercent } = calcTotals(data);
    const { value, percent } = formatTotals({ rawValue, rawPercent });
    const fontSize = calcFontSize(rawValue);
    selectedItem.name = "Total";
    selectedItem.custom.value = value;
    selectedItem.custom.percent = percent;
    selectedItem.custom.fontSize = fontSize;
  } else if (data[selected]) {
    selectedItem = data[selected];
  }

  const subtitle = `<div style="
        display: grid;
        gap: 10px;
        font: 400 16px/1 var(--font-myriad-pro);
        text-align: center;
        color: #1A1919;
      " aria-hidden="true">
        <div>${selectedItem.name ?? ""}</div>
        <div style="
          font-size: ${selectedItem?.custom.fontSize ?? 40}px;
          font-weight: 600;
          letter-spacing: -1px;
        ">${selectedItem?.custom.value ?? ""}</div>
        <div style="
          margin-top: 4px;
          color: #61779E;
        ">${
          selectedItem?.custom.percent === "0.00%"
            ? "--"
            : (selectedItem?.custom.percent ?? "")
        }</div>
      </div>`;

  const isSelectedNameTotal = selected === null;
  useEffect(() => {
    let timeoutId = null;
    if (!isSelectedNameTotal) {
      timeoutId = setTimeout(() => {
        setSelected(null);
      }, 5000);
    }

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
  }, [isSelectedNameTotal]);

  return {
    accessibility: { enabled: false }, // handled by table
    credits: { enabled: false },
    exporting: { enabled: false },
    chart: {
      type: "pie",
    },
    subtitle: {
      useHTML: true,
      floating: true,
      verticalAlign: "middle",
      text: subtitle,
    },
    title: { text: "" }, // // handled by card header
    tooltip: {
      outside: true, // displays tooltips above content (last in DOM)
      useHTML: true,
      shared: true,
      backgroundColor: "transparent",
      borderColor: "transparent",
      shadow: false,
      padding: 0,
      formatter() {
        // @ts-expect-error - highchart types are terrible
        const el = this.point as AssetAllocationChartItem;
        return `<div style="
          padding: 11px 15px;
          border-radius: 6px;
          font: 400 14px/24px var(--font-myriad-pro);
          border: 1px solid #E6E9EC;
          background-color: white;
          color: #1A1919;
          box-shadow:
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
            rgba(0, 0, 0, 0.1) 0px 2px 4px -2px;
        ">
          <div style="font-weight:600">${el.name}</div>
          <div>${el.custom.value} (${el.custom.percent})</div>
        </div>`;
      },
    },
    plotOptions: {
      series: { dataLabels: { enabled: false } },
      pie: { fillColor: "#E6E9EC" },
    },
    series: [
      {
        borderRadius: 0,
        innerSize: "75%",
        data: data.map((d) => {
          d.events = {
            click: function () {
              const data = this as any as { index: number };
              setSelected(data.index);
            },
          };
          return d;
        }),
      },
    ],
  };
}
