"use client";

import {
  GetAutocompleteResponse,
  useQueryOptionsGetAutocomplete,
} from "#app/_api/market-research-service";
import { logError } from "#app/lib/logger";
import { Badge } from "#ui/badge";
import { LinkButton } from "#ui/link";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverPortal,
} from "#ui/popover";
import {
  SearchInput,
  type SearchInputProps,
  type SearchInputRef,
} from "#ui/search-input";
import { Spinner } from "#ui/spinner";
import { cx } from "#ui/style.utils";
import { useQuery } from "@tanstack/react-query";
import NextLink, { type LinkProps as NextLinkProps } from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import { forwardRef, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";

export type SymbolSearchRef = SearchInputRef;

export type SymbolSearchProps = Omit<SearchInputProps, "name" | "value"> & {
  isAuthed?: boolean;
};

const SymbolSearch = forwardRef<SymbolSearchRef, SymbolSearchProps>(
  ({ isAuthed, onChange, ...props }, ref) => {
    // Navigation
    const router = useRouter();

    // Input
    const inputRef = useRef<HTMLInputElement>(null);

    // Popover
    const popoverRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);

    // Search Params
    const searchParams = useSearchParams();
    const initialSearchTerm = searchParams.get("searchTerm") ?? "";

    // Search State
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
    const hasSearchTerm = searchTerm.trim().length > 0;

    // Query
    const { data, error, isError, isFetching } = useQuery({
      ...useQueryOptionsGetAutocomplete({ search: searchTerm }),
      enabled: hasSearchTerm,
      retry: false,
    });

    const isFound = !!data && data.data.length > 0;
    const isEmpty = !isFetching && !isFound;

    // Errors
    if (isError) {
      logError(error);
    }

    const [hrefAll, hrefDetail] = createHrefs({ isAuthed, searchTerm });

    return (
      <Popover open={open} onOpenChange={setOpen} modal>
        {/*
        Same height as SearchInput (34px) + a bit extra (6px)
        with an negative margin offset to pull the input up over it.
        This sets the popover start position on:
        - smaller screens above the input
        - larger screens below the input
        */}
        <PopoverAnchor className="-mb-40px h-40px" />

        <SearchInput
          {...props}
          aria-label="Symbol Search"
          name="symbol-search"
          placeholder="Search"
          autoCorrect="false"
          autoComplete="false"
          spellCheck="false"
          value={searchTerm}
          onFocus={(event) => {
            if (!open && event.currentTarget.value.trim().length > 0) {
              setOpen(true);
            }
          }}
          onChange={(event) => {
            setSearchTerm(event.currentTarget.value);
            onChange?.(event);
          }}
          onKeyDown={(event) => {
            // No spaces
            if (event.key === " ") {
              event.preventDefault();
            }
            // Submit-like behavior
            if (event.key === "Enter") {
              inputRef.current?.blur();
              setOpen(false);
              router.replace(hrefAll);
              return;
            }
            // Close when focusing to the next element
            if (event.key === "Tab") {
              setOpen(false);
            }
          }}
          onKeyUp={(event) => {
            if (
              !open &&
              event.currentTarget.value.trim().length > 0 &&
              event.key !== "Tab"
            ) {
              setOpen(true);
            }
            // Move focus to popover
            if (event.key === "ArrowDown") {
              popoverRef.current?.querySelector("a")?.focus();
            }
          }}
          ref={mergeRefs([inputRef, ref])}
        />

        <PopoverPortal>
          <PopoverContent
            align="start"
            onOpenAutoFocus={(event) => {
              // Prevent focusing off the input
              event.preventDefault();
            }}
            onCloseAutoFocus={(event) => {
              // Prevent focusing the trigger
              event.preventDefault();
            }}
            onKeyUp={(event) => {
              // Keyboard-cycle through popover anchors
              if (["ArrowUp", "ArrowDown"].includes(event.key)) {
                const targets = [...event.currentTarget.querySelectorAll("a")];
                const currIdx = targets.findIndex((el) => el === event.target);
                const lastIdx = targets.length - 1;
                if (event.key === "ArrowUp") {
                  targets[currIdx === 0 ? lastIdx : currIdx - 1]?.focus();
                } else {
                  targets[currIdx === lastIdx ? 0 : currIdx + 1]?.focus();
                }
              }
            }}
            className="min-w-200px p-0"
            ref={popoverRef}
          >
            {isFetching ? <SymbolSearchPending /> : null}

            {isEmpty ? <SymbolSearchNotFound /> : null}

            {isFound ? (
              <SymbolSearchFound
                className="py-4px"
                data={data}
                itemHref={hrefDetail}
                onClickItem={() => {
                  setSearchTerm("");
                  setOpen(false);
                }}
              />
            ) : null}

            <div className="p-8px">
              <SymbolSearchButtonAll
                href={hrefAll}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    );
  },
);

SymbolSearch.displayName = "SymbolSearch";

export { SymbolSearch };

function createHrefs({
  isAuthed = false,
  searchTerm,
}: {
  isAuthed?: boolean;
  searchTerm: string;
}): [hrefAll: string, hrefDetail: (symbol: string) => string] {
  const baseUrl = isAuthed ? "/investing-tools/stocks" : "/market-tools/stocks";
  const searchTermEncoded = encodeURIComponent(searchTerm);
  const hrefAll = `${baseUrl}?searchTerm=${searchTermEncoded}`;
  const hrefDetail = (symbol: string) => `${baseUrl}/${symbol}-US`;
  return [hrefAll, hrefDetail];
}

function SymbolSearchPending({
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, "children">) {
  return (
    <div
      {...props}
      className={cx("grid place-content-center p-8px", className)}
    >
      <Spinner size="sm" />
    </div>
  );
}
function SymbolSearchNotFound({
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"div">, "children">) {
  return (
    <div {...props} className={cx("px-8px pt-8px text-sm", className)}>
      No results found
    </div>
  );
}

function SymbolSearchFound({
  data,
  itemHref,
  onClickItem,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"ul">, "children"> & {
  data: Awaited<GetAutocompleteResponse>;
  itemHref: (symbol: string)=> string;
  onClickItem: (item: Awaited<GetAutocompleteResponse>["data"][number]) => void;
}) {
  return (
    <ul {...props}>
      {data?.data.map((el) => (
        <li key={el.symbol} className="px-4px">
          <SymbolSearchItem
            symbol={el.symbol}
            href={itemHref(el.symbol)}
            onClick={() => onClickItem(el)}
          >
            {el.name}
          </SymbolSearchItem>
        </li>
      ))}
    </ul>
  );
}

function SymbolSearchButtonAll({
  className,
  href,
  ...props
}: Omit<
  React.ComponentPropsWithoutRef<typeof LinkButton>,
  "children" | "size" | "variant" | "palette"
>) {
  return (
    <LinkButton
      {...props}
      href={href}
      size="sm"
      variant="outline"
      palette="neutral"
      className={cx("w-full", className)}
    >
      All search results
    </LinkButton>
  );
}

function SymbolSearchItem({
  children,
  className,
  href,
  symbol,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"a">, "href"> &
  NextLinkProps & { symbol: string }) {
  return (
    <NextLink
      {...props}
      href={href}
      className={cx(
        "flex items-baseline gap-x-10px rounded p-4px",
        "text-sm leading-none",
        "hover:bg-shade-4 focus:bg-shade-4",
        className,
      )}
    >
      <Badge
        size="sm"
        palette="info"
        textOnly
        className="min-w-max shrink-0 basis-1/5 justify-center"
      >
        {symbol}
      </Badge>{" "}
      <span className="max-w-200px flex-1 whitespace-normal">{children}</span>
    </NextLink>
  );
}

/***
 * Market details:

    78 - Nasdaq

    42 - NYSE

    45 - NYSE Arca

    46 - NYSE Mkt

    77 - Finra other OTC Issues

    555 - Nasdaq Mutual Funds

    179 - CBOE

    388 - Nasdaq Global Indices

    431 - Dow Jones Indices


export function SymbolSearch({ onSearchResultClick, onAllSearchResultsClick }: SymbolSearchProps) {
  return (
    <FactSetWidget
      config={[
        { type: "event", key: "all-search-results", value: onAllSearchResultsClick },
        { type: "event", key: "select-result", value: onSearchResultClick },
        { type: "attribute", key: "identifierEmitted", value: "tickerRegion" },
        // Ensures that only symbols tradable by Baird Clients appears in the search
        { type: "attribute", key: "criteria", value: {
          generic: {
              validation: {
                  market: {
                      selection: {
                          restrict: {
                              ids: [
                                179,
                                  42,
                                  45,
                                  46,
                                  77,
                                  78,
                                  555,
                                  388,
                                  431
                              ]
                          }
                      }
                  }
              }
          },
          market: {
              order: {
                  ids: [
                          42,
                          45,
                          46,
                          77,
                          555,
                          179,
                          388,
                          431,
                          78,
                      ]
                  }
              }
          }
      }
      ,
      ]}
      pendingElement={null} // We don't want to show a spinner when the search is pending
      errorElement={null} // We don't want to show an error message when the search fails
      widget="search-suggester"
    />
*/
