"use client";

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { PropsWithChildren, ReactNode, Suspense, useEffect, useState } from "react";

const DefaultOnSSR = () => null;

interface ClientRenderOnlyProps extends PropsWithChildren {
  onSSR?: ReactNode | undefined;
}

const queryClient = new QueryClient();
const persister = createSyncStoragePersister({
  storage: globalThis.window ? window.sessionStorage : null,
});

export default function ClientRenderOnlys({ onSSR, children }: ClientRenderOnlyProps) {
  const [canRender, setCanRender] = useState(false);
  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    setCanRender(true);
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, []);

  if (!canRender) {
    return <>{onSSR ? onSSR : <DefaultOnSSR></DefaultOnSSR>}</>
  }


  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtools initialIsOpen />
        </Suspense>
      )}
      {children}
    </PersistQueryClientProvider>
  );
}
