import { AccountGroupService, getAccountGroups } from "../user-profile-service";
import { getPortfolio, Portfolio } from "./portfolio-service";

export async function getAccountGroupValues(): Promise<{accountGroups: AccountGroupService[]; portfolios: Portfolio[]}> {
    const groups = await getAccountGroups();
    const groupValues = await Promise.all(groups.map(el => getPortfolio({groupId: el.id})));

    if(groups.length !== groupValues.length){
        throw new Error("number of results does not match number of requests");
    }
    return { accountGroups: groups, portfolios: groupValues};    
}



